import { QuantitySelector as UIQuantitySelector } from '@faststore/ui'
import { memo, useEffect } from 'react'
import Icon from 'src/components/ui/Icon'

import styles from './quantity-selector.module.scss'

interface QuantitySelectorProps {
  quantity: number
  cartQuantity?: number
  setQuantity: (state: number) => void
  updateItems: (id: string, quantity: number) => void
  removeItem: (id: string) => void
  className?: string
  iconWidth?: number
  productId?: string
  onChange?: (value: number) => void
}

export function QuantitySelector({
  quantity,
  cartQuantity,
  setQuantity,
  removeItem,
  updateItems,
  className,
  iconWidth,
  productId,
  onChange,
}: QuantitySelectorProps) {
  const changeQuantity = (increaseValue: number) => {
    if (!productId) {
      return
    }

    const quantityValue = quantity + increaseValue

    onChange?.(quantityValue)

    setQuantity(quantityValue)
    updateItems(productId, quantityValue)
  }

  const increase = () => changeQuantity(1)

  const decrease = () => {
    if (!productId) {
      return
    }

    if (quantity === 1) {
      onChange?.(quantity)
      removeItem(productId)
    } else {
      changeQuantity(-1)
    }
  }

  useEffect(() => {
    if (cartQuantity) {
      setQuantity(cartQuantity)
    }
    // used eslint-disable to avoid infite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartQuantity])

  const style = {
    backgroundColor: '#F2F2F2',
    padding: 0,
    width: 32,
    height: 32,
  }

  return (
    <UIQuantitySelector
      className={`${styles.fsQuantitySelector} ${className ?? ''}`}
      quantity={quantity}
      leftButtonProps={{
        onClick: decrease,
        icon: (
          <Icon
            name="Minus"
            width={iconWidth ?? 24}
            height={iconWidth ?? 24}
            weight="bold"
          />
        ),
        style,
        testId: 'store-quantity-selector-left',
      }}
      rightButtonProps={{
        onClick: increase,
        icon: (
          <Icon
            name="Plus"
            width={iconWidth ?? 24}
            height={iconWidth ?? 24}
            weight="bold"
          />
        ),
        style,
        testId: 'store-quantity-selector-right',
      }}
      inputProps={{
        onChange: () => null,
        readOnly: true,
      }}
    />
  )
}

export default memo(QuantitySelector)
