import { Button as UIButton } from '@faststore/ui'
import type { ButtonProps } from '@faststore/ui'

import MiniCartIcon from '../Icons/MiniCartIcon'

interface Props extends ButtonProps {
  hasIcon?: boolean
}

function BuyButton({ children, hasIcon = true, ...props }: Props) {
  return (
    <UIButton className="button" data-store-buy-button {...props}>
      {hasIcon && <MiniCartIcon />}
      {children}
    </UIButton>
  )
}

export default BuyButton
